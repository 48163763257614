import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, delay, of } from "rxjs";
import { ProductPriceHistoryChartModel, ProductPriceHistoryChartRequestModel } from "../interfaces/product-price-history.interface";
import { environment } from "@/environments/environment";
import { JobModel } from "../interfaces/job-status.interface";
import { BaseResponseModel } from "../interfaces/base-response.interface";
import { Report, TableauTrustedUrlResponseDto } from "../interfaces/report.interface";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  
  constructor(private http: HttpClient) { }

  getProductPriceHistoryChart(body: ProductPriceHistoryChartRequestModel): Observable<ProductPriceHistoryChartModel> {
    let result = {
      "xAxis": {
        "label": "Date",
        "data": [
          "2024-06-03",
          "2024-06-04",
          "2024-06-05",
          "2024-06-06",
          "2024-06-07",
          "2024-06-08",
          "2024-06-09",
          "2024-06-10",
          "2024-06-11",
          "2024-06-03",
          "2024-06-04",
          "2024-06-05",
          "2024-06-06",
          "2024-06-07",
          "2024-06-08",
          "2024-06-09",
          "2024-06-10",
          "2024-06-11"
        ]
      },
      "yAxis": {
        "label": "Price",
        "data": []
      },
      "series": [
        {
          "name": "Cefalexin 250mg lọ 100 viên nang Tiền Giang",
          "data": [
            91000,
            91000,
            91000,
            91000,
            91000,
            91000,
            91000,
            91000,
            95000
          ]
        },
        {
          "name": "Sủi Plusszs Alpha Multivitamin Plus cọc 10 tuýp Hạnh Dung",
          "data": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            115000
          ]
        }
      ]
    } as ProductPriceHistoryChartModel;
    //return of(result).pipe(delay(500));
    return this.http.post<ProductPriceHistoryChartModel>(`${environment.baseURL}v1/report/productpricehistory/product-price-history-chart`, body);
  }

  getSyncPricingTableStatuses(): Observable<JobModel[]> {
    return this.http.get<JobModel[]>(`${environment.baseURL}v1/report/syncproduct/sync-pricing-table-status`);
  }
  getSyncPricingTableStatusById(jobId: string): Observable<JobModel> {
    return this.http.get<JobModel>(`${environment.baseURL}v1/report/syncproduct/sync-pricing-table-status/${jobId}`);
  }

  deleteSyncPricingTableJob(jobId: string): Observable<BaseResponseModel> {
    return this.http.post<BaseResponseModel>(`${environment.baseURL}v1/product/cancel-waiting-job`, JSON.stringify(jobId), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getUserTableauServices(): Observable<Report[]> {
    return this.http.get<Report[]>(`${environment.baseURL}tableaureport/reports`)
  }

  getTrustedUrl(reportName: string): Observable<TableauTrustedUrlResponseDto> {
    return this.http.get<TableauTrustedUrlResponseDto>(`${environment.baseURL}tableaureport/getTrustedUrl/${reportName}`)
  }

  getExportTemplates(body: any): Observable<any> {
    return this.http.post(`${environment.baseURL}ExportTemplate/retrieve`, body);
  }

  createExportTemplate(body: any): Observable<any> {
    return this.http.post(`${environment.baseURL}ExportTemplate`, body);
  }

  updateExportTemplate(body: any): Observable<any> {
    return this.http.put(`${environment.baseURL}ExportTemplate`, body);
  }

  exportData(body: any) {
    return this.http.post(`${environment.baseURL}ExportData`, body, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  deleteTemplate(templateId: string) {
    return this.http.delete(`${environment.baseURL}ExportTemplate/${templateId}`);
  }
}